import HowItWorks from '@components/Marketing/HowItWorks';
import Footer from '@components/Footer/Footer';
import React from 'react';
import Container from '@components/Container/Container';
import Packs from '@components/Marketing/Packs';
import Pricing from '@components/Model/Pricing/Pricing';
import Examples from '@components/Marketing/Cards/Examples';
import InstagramConnect from '@components/Marketing/Cards/InstagramConnect';
import MinimalNav from '@components/Nav/MinimalNav';
import { NextSeo } from 'next-seo';
import { trackEvent } from '@analytics/trackEvent';
import { AnalyticsEvents } from '@analytics/AnalyticsEvents';
import OpenPacks from '@components/Marketing/Cards/OpenPacks';
import Hero4 from '@components/Marketing/Hero4';
import PhotoGallery from '@components/Marketing/PhotoGallery';
import FAQ from '@components/FAQ/FAQ';
import Usecases from '@components/Marketing/Usecases';
import Quality from '@components/Marketing/Quality';
import Hero092724 from '@components/Marketing/Hero-09-27-24';

export default function Marketing() {
  React.useEffect(() => {
    trackEvent(AnalyticsEvents.marketingView, {
      page: 'Marketing'
    });
  }, []);

  return (
    <>
      <NextSeo
        title="PicStudio.AI: Pro portraits created with AI"
        description="The easiest way to professional portraits with AI! Upload selfies, choose a photo pack & receive 120+
        stunning portraits. Perfect for social media."
        openGraph={{
          images: [
            {
              url: 'https://www.picstudio.ai/default-cover-sep-2024.jpg',
              secureUrl: 'https://www.picstudio.ai/default-cover-sep-2024.jpg',
              width: 1200,
              height: 600,
              alt: 'PicStudio.AI Cover',
              type: 'image/jpg'
            }
          ]
        }}
        twitter={{
          handle: '@picstudioai',
          site: '@picstudioai',
          cardType: 'summary_large_image'
        }}
      />
      <MinimalNav className="bg-transparent" />
      <Hero092724 />
      <Container className="max-w-screen-2xl">
        <div className="bg-background">
          <div className="flex flex-col gap-8">
            <div className="lg:-mt-24">
              <PhotoGallery />
            </div>
            {/* <Hero2 /> */}
            <div className="lg:mt-16 mb-16">
              <Usecases />
              <Quality />
            </div>
            <div className="isolate w-[calc(100vw-16px)] bg-muted dark:bg-background flex justify-center left-1/2 -translate-x-1/2 relative  overflow-hidden flex-col mb-10">
              <div className="flex flex-col gap-10 max-w-[1376px] m-auto px-4 pb-10">
                {' '}
                <Hero4 />
                <HowItWorks />
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-16">
                  <div className="overflow-hidden rounded-lg bg-card">
                    <div className="px-4 py-5 sm:p-6">
                      <OpenPacks />
                    </div>
                  </div>
                  <div className="overflow-hidden rounded-lg bg-card">
                    <div className="px-4 py-5 sm:p-6">
                      <InstagramConnect />
                    </div>
                  </div>
                  <div className="overflow-hidden rounded-lg bg-card">
                    <div className="px-4 py-5 sm:p-6">
                      <Examples />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Packs />
            <div id="pricing">
              <Pricing />
            </div>
            <FAQ />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
