import React, { useState, useEffect, useMemo } from 'react';
import Image from 'next/image';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { type CarouselApi } from '@/components/ui/carousel';
import Autoplay from 'embla-carousel-autoplay';

export default function OriginalVsGenerated() {
  const showcaseSlideshowLeft = [
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d48a7331-fdce-4521-340e-4c4c3f2e7300/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/e1d5707e-2f39-47c6-a12f-c47ad014d200/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/6511568f-b0f5-4764-10a8-5e247488e700/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b8700e40-31f3-4298-dda0-a8bd62a9a400/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/2ec14dae-ce72-4ab6-6a44-56e2cccd0800/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/3430ddac-2b75-4a26-d9aa-7ffad5f83700/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/4ca94c5d-f4b3-4b94-d298-83e6c75d2e00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/2f92c860-20b4-4107-16de-e6ab0c582a00/public'
  ];

  const showcaseSlideshowRight = [
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/b7869cbf-b7d9-4fff-91ee-c1a58d182e00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/074ce4aa-e952-42df-1d32-b08d716ae000/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/cf3e7094-cc56-4454-3380-9e7b682c8d00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/decd70f3-434b-4208-b866-36262be13300/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/35c02d85-ec4f-4ad8-f261-81ce3ac3c600/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/364ddc2d-10e6-4493-79db-25cd117c6800/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/61684804-82ec-4f06-4f3d-64a039461f00/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/a8b7e380-9280-431a-918e-4f62eab58e00/public'
  ];

  const leftThumbnails = [
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/ab3fa128-01c5-40f8-326b-616743d9d900/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/7edd2eed-7ce2-493c-9f78-98062f915900/public',
    'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/32070298-6f2d-45a3-7e88-e00783e6f700/public'
  ];

  const rightThumbnails = [
    '/images/marketing/klee1.webp',
    '/images/marketing/klee3.webp',
    '/images/marketing/klee4.webp'
  ];

  const MemoizedThumbnailStack = React.memo(({ thumbnails }: { thumbnails: string[]; isLeft: boolean }) => (
    <div className="flex flex-row md:flex-col items-center gap-2 relative">
      <span className="hidden md:block text-foreground text-xs mb-0">Selfies</span>
      {thumbnails.map((thumb, index) => (
        <div key={index} className="w-8 h-8 md:w-12 md:h-12 flex-shrink-0">
          <Image
            src={thumb}
            width={48}
            height={48}
            alt={`Thumbnail ${index + 1}`}
            className="object-cover w-full h-full rounded-md"
          />
        </div>
      ))}
    </div>
  ));

  MemoizedThumbnailStack.displayName = 'MemoizedThumbnailStack';

  const CarouselImages = React.memo(({ images }: { images: string[] }) => {
    const [api, setApi] = useState<CarouselApi>();
    const [current, setCurrent] = useState(0);
    const [count, setCount] = useState(0);

    const plugin = React.useMemo(() => Autoplay({ delay: 4000, stopOnInteraction: true }), []);

    useEffect(() => {
      if (!api) {
        return;
      }

      setCount(api.scrollSnapList().length);
      setCurrent(api.selectedScrollSnap() + 1);

      api.on('select', () => {
        setCurrent(api.selectedScrollSnap() + 1);
      });
    }, [api]);

    return (
      <div className="relative w-full group">
        <Carousel
          setApi={setApi}
          className="w-full rounded-lg md:rounded-3xl overflow-hidden"
          opts={{
            align: 'start',
            loop: true
          }}
          plugins={[plugin]}
        >
          <CarouselContent>
            {images.map((src, index) => (
              <CarouselItem key={index}>
                <div className="w-full relative">
                  <div className="bg-neutral-800 mix-blend-multiply px-4 py-2 absolute bottom-4 left-1/2 transform -translate-x-1/2 flex w-fit rounded-full whitespace-nowrap text-white/0 font-bold">
                    AI Generated
                  </div>
                  <div className="px-4 py-2 absolute bottom-4 left-1/2 transform -translate-x-1/2 flex w-fit rounded-full z-20 whitespace-nowrap text-amber-400 font-bold">
                    <span className="bg-gradient-to-r from-[#BE22FF] via-[#FF328F] to-[#FF981F] text-transparent bg-clip-text">
                      AI Generated
                    </span>
                  </div>
                  <Image
                    src={src}
                    width={1000}
                    height={1000}
                    alt={`AI Generated Image ${index + 1}`}
                    className="object-cover h-fit aspect-[2/3] "
                  />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            variant="default"
            className="left-4 opacity-80 hover:opacity-100 z-10 md:opacity-0 md:group-hover:opacity-100"
          />
          <CarouselNext
            variant="default"
            className="right-4 opacity-80 hover:opacity-100 z-10 md:opacity-0 md:group-hover:opacity-100"
          />
        </Carousel>
        <div className="z-20 absolute bottom-3 left-4 right-4 flex justify-center space-x-0.5">
          {Array.from({ length: count }).map((_, index) => (
            <div
              key={index}
              className={`h-1 w-full ${current === index + 1 ? 'opacity-100 bg-white' : 'opacity-50 bg-gray-400'}`}
              style={{ transition: 'opacity 0.3s' }}
            />
          ))}
        </div>
      </div>
    );
  });

  CarouselImages.displayName = 'CarouselImages';

  const memoizedLeftStack = useMemo(() => <MemoizedThumbnailStack thumbnails={leftThumbnails} isLeft={true} />, []);
  const memoizedRightStack = useMemo(() => <MemoizedThumbnailStack thumbnails={rightThumbnails} isLeft={false} />, []);

  return (
    <div className="flex items-center justify-center max-w-4xl mx-auto overflow-hidden text-white text-xs md:text-lg">
      <div className="flex md:flex-row gap-2 md:gap-4">
        <div className="flex flex-col md:flex-row w-1/2 relative items-center justify-center gap-2">
          {memoizedLeftStack}
          <CarouselImages images={showcaseSlideshowLeft} />
        </div>
        <div className="flex flex-col md:flex-row w-1/2 relative items-center justify-center gap-2">
          <CarouselImages images={showcaseSlideshowRight} />
          {memoizedRightStack}
        </div>
      </div>
    </div>
  );
}
