import React from 'react';

import { Button } from '@components/ui/button';
import Link from 'next/link';
import { useSession } from '@hooks/index';
import { useAnimation } from 'framer-motion';
import PicStudioVideoSep2023 from './PicStudioVideoSep2023';

import NumberOne from '@components/Badge/NumberOne';
import SocialProof from '@components/Cards/SocialProof';
import OriginalVsGenerated from './OriginalVsGenerated';

export default function Hero092724() {
  const { isLoggedIn } = useSession();
  const controls = useAnimation();

  React.useEffect(() => {
    controls.start({
      x: '-50%',
      transition: {
        repeat: Infinity,
        duration: 60,
        ease: 'linear'
      }
    });
  }, [controls]);

  return (
    <div className="bg-background relative isolate overflow-hidden  mb-6 lg:mb-16">
      <div className="mx-auto">
        <div className="absolute flex w-full top-0 left-0 right-0 h-full object-cover -z-40 bg-gradient-to-t from-background/90 via-background/90 to-background/70" />
        <div className="bg-red-500/0 grid grid-cols-1 px-4 md:px-10 lg:px-20 m-auto pt-6 lg:py-10 gap-6 pb-0">
          <div className="bg-red-500/0 relative flex flex-col items-center justify-center md:p-6 w-full">
            <div className="mb-6 flex gap-10">
              <NumberOne />
            </div>
            <div className="mb-10">
              <OriginalVsGenerated />
            </div>

            <div className="relative flex flex-col m-auto w-full">
              <h1 className="text-2xl md:text-4xl lg:text-6xl xl:text-7xl font-bold tracking-normal text-primary text-center">
                Generate photos <br />
                of yourself with AI
                <div className="mt-2 text-xs lg:text-lg text-muted-foreground">(Photos that look 100% real)</div>
              </h1>

              <div className="mt-6 lg:mt-10 m-auto mx-0 flex-col w-full flex md:flex-row items-center justify-center gap-x-6 gap-y-4">
                {!isLoggedIn ? (
                  <div className="w-full lg:w-fit relative">
                    <div className="absolute -top-8 left:0 md:left-1/2 transform md:-translate-x-1/2">
                      <div className="bg-foreground text-background text-[10px] font-bold px-2 py-1 rounded-full relative  animate-bounce">
                        NEW VERSION
                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-foreground"></div>
                      </div>
                    </div>
                    <Button className="w-full" variant={'accent'} size={'xl'} asChild>
                      <Link href="/#pricing">Get your portraits</Link>
                    </Button>
                  </div>
                ) : (
                  <>
                    <Button className="w-full md:w-fit" variant={'accent'} size={'xl'} asChild>
                      <Link href="/dashboard" className="text-sm font-semibold leading-6 text-studio-900">
                        Go to dashboard
                      </Link>
                    </Button>
                  </>
                )}
                <PicStudioVideoSep2023 />
              </div>
              <SocialProof />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
